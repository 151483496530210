import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Home from '../Pages/home';
import axios from 'axios';
import About from "../Pages/about";

import Enrollpage from '../Pages/enrollpage';
import Enroll from '../Pages/Coursedetails';
import Courses from '../Pages/courses';
import Mylearning from '../Pages/mylearning';
import PrivacyPolicy from '../Pages/privacy';
import ResetPassword from '../components/ResetPassword';
import Coder from '../Pages/Coder';
import Coder_2 from '../Pages/Coder_2';
import Coder_3 from '../Pages/Coder_3';
import LabActivities from '../Pages/LabActivities';
axios.defaults.baseURL = 'https://api.iparhai.com/';
// axios.defaults.baseURL = 'http://localhost:8000';
function Navigation() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/"  element={<Home />} /> 
        <Route path="/about"  element={<About />} /> 
        <Route path="/privacypolicy"  element={<PrivacyPolicy />} />
        <Route path="/mylearning"  element={<Mylearning />} /> 
       <Route path="/Courses" element={<Courses/>}/>
        <Route path="/enrollpage/:id/:uid" element={<Enrollpage />} /> 
        <Route path="/coursedetails/:id/:uid" element={<Enroll />} />
        <Route path="/resetpassword/:resetToken" element={<ResetPassword />} />
        <Route path="/Coder" element={<Coder />} />
        <Route path="/Coder_2" element={<Coder_2 />} />
        <Route path="/Coder_3" element={<Coder_3 />} />
        <Route path="/LabActivities" element={<LabActivities />} />
      </Routes> 
    </HashRouter>
  );
}

export default Navigation;
