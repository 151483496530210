import React, { useState } from "react";
import overallservices from "../services/overallservices";
import { useEffect } from "react";
import Iframe from "react-iframe";
const Video = ({ Dynamicvideo }) => {
  const [isLoading, setLoading] = useState(false);
  console.log(Dynamicvideo, "linkhere");
  return (
    <>
      {isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}></div>
      )}
      {!isLoading && (
        <iframe
          width="560"
          height="315"
          src={Dynamicvideo}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      )}
    </>
  );
};

export default Video;
