import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const RecommendationComponent = ({ score, outOf }) => {
  const passThreshold = outOf * 0.5; //calculate 50% of total questions

  return (
    <Box sx={{ flexGrow: 1, textAlign: "center", marginTop: "20px" }}>
      <Typography variant="h5" color="textSecondary">
        Total Questions: {outOf} | Your Score: {score}
      </Typography>

      {score < passThreshold ? (
        //Test failed so show recommendations
        <>
          <Typography variant="h4" color="error" sx={{ marginTop: "16px" }}>
            You failed to pass the test ☹️
          </Typography>
          {/* <Typography variant="body1" sx={{ marginTop: "16px" }}>
            Here are some recommendations to improve your skills:
          </Typography> */}
          {/* <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Recommended Skills</Typography>
              </Grid>
              <Grid item xs={8}>
                <Item>
                  <b>Chemical Equation</b>
                </Item>
              </Grid>
              <Grid item xs={8}>
                <Item>
                  <b>Branches of Chemistry</b>
                </Item>
              </Grid>
              <Grid item xs={8}>
                <Item>
                  <b>Atomic Structure</b>
                </Item>
              </Grid>
            </Grid>
          </Box> */}
        </>
      ) : (
        //Test passed so show success message
        <>
          <Typography variant="h4" color="primary" sx={{ marginTop: "16px" }}>
            Congratulations! You have passed the test 😎
          </Typography>
        </>
      )}
    </Box>
  );
};

export default RecommendationComponent;
