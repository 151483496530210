import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

// Declare pyodide to avoid ESLint error
let pyodide;

const Coder = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [boxes, setBoxes] = useState([1]);
  const [responseText, setResponseText] = useState("");
  const [namespaces, setNamespaces] = useState({});
  const [codeMirrorInstances, setCodeMirrorInstances] = useState({});

  const cleanupBeforePyodideLoad = () => {
    let largeArray = new Array(1e6).fill("data");
    largeArray = null; // Allow GC to reclaim memory
    // Clear any memory-heavy JavaScript objects or DOM elements that are not needed
    if (window.performance && window.performance.memory) {
      console.log('Memory before Pyodide load: ', window.performance.memory);
    }
  
    // Optionally clear event listeners, variables, or elements that won't be used
    //window.removeEventListener("scroll", handleScroll);
  };

  useEffect(() => {
    cleanupBeforePyodideLoad();
    const handleScroll = () => {
      const button = document.getElementById("goToTopButton");
      if (window.scrollY > 200) { // Show button when scrolled down more than 200px
        button.style.display = "block";
      } else {
        button.style.display = "none";
      }
    };
    window.addEventListener("scroll", handleScroll);
    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/pyodide/v0.26.2/full/pyodide.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = async () => {
      console.log("Pyodide loaded successfully.");
      
      // Set a timeout for loading Pyodide
      const loadTimeout = new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Pyodide loading timed out")), 30000) // 30 seconds timeout
      );
    
      try {
        pyodide = await Promise.race([loadPyodide(), loadTimeout]); // Wait for Pyodide or the timeout
    
        setIsScriptLoaded(true);
    
        // Output status messages
        const output = document.getElementById("output-1");
        output.innerHTML += "Pyodide loaded! Loading packages...\n";
    
        await pyodide.loadPackage(["numpy", "pandas", "matplotlib", "scikit-learn"]);
        output.innerHTML += "Packages loaded! Ready to execute code.\n";
    
        await pyodide.loadPackage("micropip");
        const micropip = pyodide.pyimport("micropip");
        await micropip.install("seaborn");
        output.innerHTML += "Additional packages installed! Ready to execute code.\n";
    
        if (isScriptLoaded) {
          window.scrollTo(0, 0);
        }
      } catch (error) {
        console.error("Error loading Pyodide:", error);
        const output = document.getElementById("output-1");
        output.innerHTML += "Failed to load Pyodide or its packages. Please try again.\n";
      }
    };
    
    let installedPackages = {
      core: ["numpy", "pandas", "matplotlib", "scikit-learn"],
      additional: ["seaborn"]  // Can be dynamically updated if more packages are installed via micropip
    };

    const cleanupPackages = () => {
      if (pyodide) {
        // Clean core packages from sys.modules
        installedPackages.core.forEach((packageName) => {
          try {
            pyodide.runPython(`import sys
if '${packageName}' in sys.modules:
  del sys.modules['${packageName}']`);
            console.log(`Removed core package from sys.modules: ${packageName}`);
          } catch (error) {
            console.error(`Failed to remove core package: ${packageName}`, error);
          }
        });
    
        // Clean additional packages from sys.modules
        installedPackages.additional.forEach((packageName) => {
          try {
            pyodide.runPython(`import sys
if '${packageName}' in sys.modules:
  del sys.modules['${packageName}']`);
            console.log(`Removed additional package from sys.modules: ${packageName}`);
          } catch (error) {
            console.error(`Failed to remove additional package: ${packageName}`, error);
          }
        });
    
        // Clear installed packages tracking list
        installedPackages = { core: [], additional: [] };
      }
    };
    
  const clearPyodideMemory = () => {
    if (pyodide) {
      try {
        pyodide.runPython(`import gc
import matplotlib.pyplot as plt
plt.close("all")
gc.collect()`);
        console.log("Cleared Pyodide memory.");
      } catch (error) {
        console.error("Error clearing Pyodide memory:", error);
      }
    }
  };    

    return () => {
      cleanupPackages();
      clearPyodideMemory();
      // Cleanup memory when the component unmounts
      window.removeEventListener("scroll", handleScroll);
  
//       if (pyodide) {
//         try {
//           pyodide.runPython(`import gc
// import matplotlib.pyplot as plt
// plt.close("all")
// gc.collect()`);
//           console.log("Cleared Pyodide memory.");

//         //pyodide.destroy();
//         console.log("Pyodide VM destroyed.");
//         } catch (error) {
//           console.error("Error clearing Pyodide memory:", error);
//         }
//       }
      pyodide = null;
      // Remove Pyodide script
      document.body.removeChild(script);
  
      // Clear CodeMirror instances
      Object.values(codeMirrorInstances).forEach((editor) => {
        if (editor) editor.toTextArea(); // Destroy editor instance
      });
  
      // Reset state variables
      setNamespaces({});
      setCodeMirrorInstances({});
      setIsScriptLoaded(false);
    };
  }, []);

  const handleUnload = () => {
    if (pyodide) {
      try {
        pyodide.runPython(`import gc
import matplotlib.pyplot as plt
plt.close("all")
gc.collect()`);
        console.log("Pyodide memory cleared on page unload.");
      } catch (error) {
        console.error("Error clearing Pyodide memory on unload:", error);
      }
    }
    pyodide = null;
  };
  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    window.addEventListener("unload", handleUnload);
  
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, []);


  const loadPyodide = async (config) => {
    return await window.loadPyodide(config);
  };
  async function sendToChatbot() {
    const chatInput = document.getElementById(`chat-input`);
    const chatOutput = document.getElementById(`chat-output`);
    const userInput = chatInput.value;
    if (!userInput) return;
  
    try {
      const response = await axios.post("/api/lab/chat", {prompt:userInput});
  
      const data = response.data;
      if (response) {
        chatOutput.value = data.response;
      } else {
        chatOutput.value = `Error: ${data.message}`;
      }
    } catch (error) {
      console.error("Error communicating with chatbot:", error);
      chatOutput.value = "An error occurred. Please try again.";
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const loadCodeMirror = async () => {
      const loadExternalScript = (src, id) =>
        new Promise((resolve, reject) => {
          if (document.getElementById(id)) return resolve();
          const script = document.createElement("script");
          script.src = src;
          script.id = id;
          script.onload = resolve;
          script.onerror = reject;
          document.body.appendChild(script);
        });

      const loadExternalStylesheet = (href, id) => {
        if (!document.getElementById(id)) {
          const link = document.createElement("link");
          link.href = href;
          link.rel = "stylesheet";
          link.id = id;
          document.head.appendChild(link);
        }
      };

      try {
        loadExternalStylesheet(
          "https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.65.9/codemirror.min.css",
          "codemirror-css"
        );
        await loadExternalScript(
          "https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.65.9/codemirror.min.js",
          "codemirror-js"
        );
        await loadExternalScript(
          "https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.65.9/mode/python/python.min.js",
          "codemirror-python-js"
        );
        setIsScriptLoaded(true);
      } catch (err) {
        console.error("Failed to load CodeMirror assets:", err);
      }
    };

    loadCodeMirror();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Function to initialize CodeMirror on all textareas inside the container


const initializeCodeMirror = () => {
  const container = document.querySelector(".container");
  if (container) {
    const textareas = container.querySelectorAll("textarea[id^='code-']");
    textareas.forEach((textarea) => {
      if (textarea && !textarea.classList.contains("cm-initialized")) {
        const editor = window.CodeMirror.fromTextArea(textarea, {
          lineNumbers: true,
          mode: "python",
          theme: "default",
          indentUnit: 4,
          tabMode: "indent",
          smartIndent: true,
          autofocus: true,
          autoCloseBrackets: true,
        });
        textarea.classList.add("cm-initialized");

        // Store the editor instance for later retrieval
        const id = textarea.id;
        setCodeMirrorInstances((prev) => ({
          ...prev,
          [id]: editor,
        }));
      }
    });
  }
};
  
    // Initialize CodeMirror initially
    if (isScriptLoaded) {
      initializeCodeMirror();
    }
  
    // Create a MutationObserver to monitor changes in the container
    const container = document.querySelector(".container");
    const observer = new MutationObserver(() => {
      initializeCodeMirror();
    });
  
    if (container) {
      observer.observe(container, { childList: true, subtree: true });
    }
  
    // Cleanup observer on component unmount
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
    window.scrollTo(0, 0);
  }, [isScriptLoaded]);
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
  };
  const CreateBox = (id, description, customCode) => {
    // Scroll to top after adding the last box
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [boxes]); // This will scroll to the top whenever boxes are updated
  
    return (
      <div
        className="box"
        style={{
          border: "2px solid #ccc",
          padding: "15px",
          marginBottom: "20px",
          backgroundColor: "#f9f9f9",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        }}
        key={id}
      >
        <p>{description}</p>
        <textarea
          id={`code-${id}`}
          style={{
            width: "100%",
            height: "150px",
            padding: "10px",
            border: "1px solid #ddd",
            backgroundColor: "#fff",
            fontFamily: "'Courier New', monospace",
            fontSize: "14px",
          }}
        >
          {customCode}
        </textarea>
        <button
          onClick={() => evaluatePython(id)}
          style={{
            backgroundColor: "#FF6652",
            color: "white",
            padding: "10px 15px",
            marginTop: "10px",
            border: "none",
            cursor: "pointer",
            borderRadius: "5px",
          }}
        >
          Run
        </button>
        <button
          id={`llm-${id}`}
          onClick={() => handleButtonClick(id)}
          style={{
            backgroundColor: "#FF6652",
            color: "white",
            padding: "10px 15px",
            marginTop: "10px",
            border: "none",
            cursor: "pointer",
            borderRadius: "5px",
          }}
        >
          Need Help?
        </button>
        <br />
        <br />
        <div>Output:</div>
        <textarea
          id={`output-${id}`}
          rows="6"
          style={{
            width: "100%",
            padding: "10px",
            border: "1px solid #ddd",
            backgroundColor: "#f0f0f0",
            fontFamily: "'Courier New', monospace",
          }}
        ></textarea>
        <div
          className="content"
          id={`bixby-section-${id}`}
          style={{ marginTop: "20px" }}
        >
          <div>iParhai's Response:</div>
          <textarea
            id={`bixby-output-${id}`}
            rows="6"
            disabled
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ddd",
              backgroundColor: "#f0f0f0",
              fontFamily: "'Courier New', monospace",
            }}
          ></textarea>
        </div>
        <div id={`Plot-${id}`}></div>
      </div>
    );
  };
  

  const saveCode = async (id) => {
    const editor = codeMirrorInstances[`code-${id}`];
    if (!editor) {
      console.error(`Editor for id ${id} not found.`);
      return;
    }

    const pythonCode = editor.getValue();

    try {
      const response = await axios.post("/api/lab/save", { code: pythonCode });

      const data = response.data;

      const bixbyOutput = document.getElementById(`bixby-output-${id}`);
      if (response) {
        bixbyOutput.value = data.response || "Code saved successfully!";
      } else {
        bixbyOutput.value = `Error: ${data.message}`;
      }
    } catch (error) {
      console.error("Error saving code:", error);
    }
  };

  function toggleCollapsible(id) {
    const bixbySection = document.getElementById(`bixby-section-${id}`);
    bixbySection.style.display = bixbySection.style.display === "block" ? "none" : "block";
  }

  function handleButtonClick(id) {
    toggleCollapsible(id);
    const bixbySection = document.getElementById(`bixby-section-${id}`);
    const llmButton = document.getElementById(`llm-${id}`);
    if (bixbySection.style.display === "block") {
      llmButton.innerText = "Got it, thanks!";
      saveCode(id);
    } else {
      llmButton.innerText = "Need Help?";
    }
  }
  

  const evaluatePython = async (id) => {
    if (isScriptLoaded) {
      const output = document.getElementById(`output-${id}`);
      const plotDiv = document.getElementById(`Plot-${id}`);
      output.value = "Running Python code...\n";
      plotDiv.innerHTML = "";
  
      let pyodideNamespace = namespaces[id] || {};
      if (!namespaces[id]) {
        setNamespaces((prev) => ({
          ...prev,
          [id]: pyodideNamespace,
        }));
      }
  
      try {
        // Retrieve the content directly from the CodeMirror instance
        const editor = codeMirrorInstances[`code-${id}`];
        let code = editor ? editor.getValue() : "";
        console.log(code);
  
        pyodide.runPython(`import sys
import matplotlib.pyplot as plt
from io import StringIO
from base64 import b64encode
from io import BytesIO

sys.stdout = StringIO()
existing_figs = list(plt.get_fignums())
for fig_num in existing_figs:
    plt.show(fig_num)
    plt.close(fig_num)

namespace = ${JSON.stringify(pyodideNamespace)}
exec("""${code}""", namespace)

images = []
for fig_num in plt.get_fignums():
    fig = plt.figure(fig_num)
    buf = BytesIO()
    fig.savefig(buf, format="png")
    buf.seek(0)
    images.append("data:image/png;base64," + b64encode(buf.read()).decode('utf-8'))
    buf.close()

sys.stdout.seek(0)
stdout_output = sys.stdout.getvalue()

{
    "stdout_output": stdout_output,
    "images": images
}`);
  
        const result = pyodide.runPython("stdout_output");
        output.value = result;
  
        const images = pyodide.runPython("images");
        images.forEach((src) => {
          const img = document.createElement("img");
          img.src = src;
          img.style.width = "100%";
          img.style.height = "auto";
          plotDiv.appendChild(img);
        });
        
      } catch (error) {
        output.value = `Error: ${error.message}`;
      }
    }
  };
  
  return (
    <>
      <Helmet>
        <title>First Lab - Pyodide & React</title>
        <meta
          name="description"
          content="Pyodide and React integration with dynamic Python code execution"
        />
      </Helmet>
      <p
      style={{
        border: "2px solid #ccc",
          padding: "15px",
          marginBottom: "20px",
          backgroundColor: "#f9f9f9",
          textAlign: "center",
          fontWeight: "bold"

      }}
      >Welcome to Your First Lab....</p>
      <div
  className="parent-container"
  style={{
    display: "flex",
    alignItems: "flex-start",
    gap: "20px", // Optional: add spacing between the sections
    width: "100%",
    padding: "20px",
  }}
>
  
  <div
    className="container"
    style={{
      width: "70%",
      paddingLeft: "20px",
    }}
  >
    <h1 style={{textAlign: "center", fontSize: "60px"}}>Introduction To Python </h1>
    <p style={{textAlign: "center"}}>This lab focuses on giving you a hands-on experience with the basics of the python.</p>
    <div id="dynamic-boxes">
     
  {/* Create two boxes directly */}
  
  {CreateBox(1,
   "Heyyy. Let's start with smth basic like making a numpy array.Down below is the code to make a list and a list of list .Press 'Run' to execute the code.",
    `import numpy as np
numlist=np.array([1, 2, 3])
print(numlist)
print("Mean:", np.mean(numlist))
numlistoflist=np.array([[1, 2, 3]])
print(numlistoflist)
print("Mean:", np.mean(numlistoflist))`)}
  {CreateBox(2,  "Great!Now let's plot such lists .",
     `import matplotlib.pyplot as plt
x = [1, 2, 3, 4, 5]
y = [2, 3, 5, 7, 11]
plt.plot(x, y)
plt.show()
`)}

{CreateBox(3,  "print the available variables ",
`name = "Alice"
age = 25
is_student = True

`)}
{CreateBox(4,  "Arithmetic Operations",
`a = 10
b = 3
print(a + b)
print(a - b)
print(a * b)
print(a / b)
print(a % b)
`)}


    </div>
  </div>
  <div
    className="layout"
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "30%",
    }}
  >
    <div className="chatbot">
      <h2>Chatbot Interaction</h2>
      <textarea
        id="chat-input"
        rows="4"
        placeholder="Type your message here..."
        style={{ width: "100%" }}
      ></textarea>
      <button
        onClick={() => sendToChatbot()}
        style={{
          marginTop: "10px",
          backgroundColor: "#FF6652",
          color: "white",
          padding: "10px 15px",
          border: "none",
          cursor: "pointer",
          borderRadius: "5px",
        }}
      >
        Send
      </button>
      <div style={{ marginTop: "20px" }}>Chatbot Response:</div>
      <textarea
        id="chat-output"
        rows="6"
        disabled
        style={{
          width: "100%",
          marginTop: "10px",
          padding: "10px",
          border: "1px solid #ddd",
          backgroundColor: "#f0f0f0",
        }}
      ></textarea>
      <button
  id="goToTopButton"
  onClick={scrollToTop}
  style={{
    position: "fixed",
    bottom: "20px",
    right: "20px",
    backgroundColor: "#FF6652",
    color: "white",
    padding: "10px 15px",
    border: "none",
    cursor: "pointer",
    borderRadius: "5px",
    display: "none", // Initially hidden
  }}
>
  Go to Top
</button>

    </div>
  </div>
</div>


    </>
  );
};

export default Coder;
