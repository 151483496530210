import React, { useState } from 'react';
import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Ilogo from "../assests/ifinal.svg"
import Profile from "../assests/Profile.svg";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Login from './login';
import Signup from './signup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { logout } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
const StyledModalPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  p: 4,
}));

// interface Props {
//   window?: () => Window;
// }

const drawerWidth = 240;
const navItems = ['Home', 'About', 'Courses', "Lab Activities"];

export default function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const navigate = useNavigate()
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const handleDrawerToggle = () => setMobileOpen(prevState => !prevState);
  const handleModalOpen = () => {
    setModalOpen(true);
    handleClose();
  };
  const handleModalClose = () => setModalOpen(false);

  const handleLoginButtonClick = () => {
    setIsLoginMode(true);
    handleModalOpen();
  };
  const logoutUser = () => {
    dispatch(logout())
    navigate("/");
  }

  const handleSignupButtonClick = () => {
    setIsLoginMode(false);
    handleModalOpen();
  };

  const theme = createTheme({
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const buttonhandleClose = () => {
    setAnchorEl(null);
  };

  const buttonopen = Boolean(anchorEl);
  const id = buttonopen ? 'simple-popover' : undefined;

  const container = window !== undefined ? () => window().document.body : undefined;
  const token = localStorage.getItem("user");
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar component="nav" sx={{ backgroundColor: '#FFFFFF' }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' }, color: 'black' }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: 'none', sm: 'block' },
                textAlign: { xs: 'center', sm: 'left' },
                my: { xs: 1, sm: 0 },
              }}
            >
              <img src={Ilogo} alt="Logo" className='h-10' style={{ marginLeft: 80 }} />
            </Typography>
            <Box
              sx={{
                display: { xs: 'none', sm: 'block' },
                flexDirection: 'row',
                alignItems: 'center',
                mr: 10,
              }}
            >

              {navItems.map((item) => {
                // Check if 'Lab Activities' should be displayed based on the presence of the toke

                // Determine the appropriate link based on the item name
                const link =
                  item === 'Lab Activities' ? '/LabActivities' :
                    item === 'Courses' ? '/courses' :
                      item === 'Home' ? '/' :
                        `/${item.toLowerCase()}`;

                return (
                  <Link
                    key={item}
                    to={link}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Button sx={{ color: 'black', m: 1 }}>
                      {item}
                    </Button>
                  </Link>
                );
              })}

              {token ? (
                <Link to="/MyLearning" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Button key="My Learning" sx={{ color: 'black', m: 1 }}>
                    My Learning
                  </Button>
                </Link>
              ) : null}
              {token ? <> <Button onClick={handleClick}
                sx={{ backgroundColor: "#fbf1ea", color: 'black', ml: 2, mt: 0, }}

              >
                Account
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                  <img src={Profile} height="20" alt="Profile" />
                </Box>
              </Button>  <Popover
                id={id}
                open={buttonopen}
                anchorEl={anchorEl}
                onClose={buttonhandleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                  <div style={{ display: "flex", flexDirection: "row" }} onClick={logoutUser}>
                    <Typography sx={{ p: 2 }}>Logout</Typography><IconButton
                      color="inherit"

                    >

                      <ExitToAppIcon />
                    </IconButton>
                  </div>
                </Popover></> :
                <>             <Button
                  sx={{
                    backgroundColor: "#fbf1ea", // Set the background color
                    color: 'black',
                    ml: 2,
                    mt: 0,
                    '&:hover': {
                      backgroundColor: "#fbf1ea", // Maintain the background color on hover
                    },
                  }}
                  onClick={handleLoginButtonClick}
                >
                  Login
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    <img src={Profile} height="20" alt="Profile" />
                  </Box>
                </Button>
                  <Button
                    sx={{
                      backgroundColor: "#FF6652", // Set the background color
                      color: '#FFFF',
                      ml: 2,
                      mt: 0,
                      '&:hover': {
                        backgroundColor: "#FF6652", // Maintain the background color on hover
                      },
                    }}
                    onClick={handleSignupButtonClick}
                  >
                    Signup
                  </Button>

                </>

              }
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            // variant={window.innerWidth <= 600 ? 'temporary' : 'persistent'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }} // Add this to fix accessibility issue
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: '#f8f8f8',
              },
            }}
          >
            <div onClick={(e) => e.stopPropagation()}>
              <Typography variant="h6" sx={{ my: 2 }}>
                <img src={Ilogo} alt="Logo" height="30" />
              </Typography>
              <Divider />
              <List>
                {navItems.map((item) => (
                  <ListItem key={item} disablePadding>

                    <Link to={item === 'Courses' ? '/courses' : `/${item.toLowerCase()}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <ListItemButton sx={{ textAlign: 'center' }}>
                        <ListItemText primary={item} />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                ))}
              </List>
              {token ? <Button onClick={handleClick}
                sx={{ backgroundColor: "#fbf1ea", color: 'black', ml: 2, mt: 2 }}

              >
                Account
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                  <img src={Profile} height="20" alt="Profile" />
                </Box>
              </Button> :
                <>            <Button
                  sx={{ color: 'black', ml: 2, mt: 0 }} // Remove backgroundColor property
                  onClick={handleLoginButtonClick}
                >
                  Login
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    <img src={Profile} height="20" alt="Profile" />
                  </Box>
                </Button>
                  <Button
                    sx={{ color: '#FFFF', ml: 2, mt: 0 }} // Remove backgroundColor property
                    onClick={handleSignupButtonClick}
                  >
                    Signup
                  </Button>

                </>

              }
            </div>
          </Drawer>
        </Box>
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar />
        </Box>
        <Modal
          className='Modal'
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="login-modal-title"
          aria-describedby="login-modal-description"
          onClick={(e) => e.stopPropagation()} // Add this onClick handler
        >
          {isLoginMode ? <Login /> : <Signup />}
        </Modal>
      </Box>
    </ThemeProvider>
  );
}
