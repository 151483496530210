import React from 'react';

const LabActivities = () => {
  const handleLabCourseClick = () => {
    window.open('/#/Coder', '_blank', 'noopener,noreferrer');
  };

  const handleLabCourse2Click = () => {
    window.open('/#/Coder_2', '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="p-6 bg-white rounded-lg shadow-lg text-center max-w-md w-full">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Lab Activities</h1>
        <p className="text-gray-600 mb-6">
          Select a lab course to start learning and enhance your skills!
        </p>

        {/* Course Cards Container */}
        <div className="space-y-4">
          {/* Intro to Python Course */}
          <div 
            className="cursor-pointer bg-[#FF6652] text-white font-semibold rounded-lg p-6 shadow-md hover:bg-[#D65445] transition duration-200"
            onClick={handleLabCourseClick}
          >
            <h2 className="text-xl font-bold">Intro to Python</h2>
            <p className="text-white mt-2">Learn the basics of Python programming in this interactive course.</p>
          </div>

          {/* Conditional Statements & Loops Course */}
          <div 
            className="cursor-pointer bg-[#FF6652] text-white font-semibold rounded-lg p-6 shadow-md hover:bg-[#D65445] transition duration-200"
            onClick={handleLabCourse2Click}
          >
            <h2 className="text-xl font-bold">Conditional Statements & Loops</h2>
            <p className="text-white mt-2">Master decision-making and iteration in Python with hands-on examples.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabActivities;
