import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  CircularProgress,
} from "@mui/material";
import RecommendationComponent from "../components/Recomendation";
import axios from "axios";

const AssessmentComponent = ({
  contentId,
  onAssessmentSubmit,
  assessments,
}) => {
  console.log("Content ID:", contentId); // Updated log message for debugging
  console.log("Assessments:", assessments);

  // Construct quiz array
  const quizArray = assessments?.map((row, index) => {
    console.log(`Row ${index} Data:`, row); // Debugging statement

    const options = [row.option1, row.option2, row.option3, row.option4];
    const correctIndex = options.findIndex((option) => option === row.correct); // New logic to derive the correct option index

    if (correctIndex === -1) {
      console.error(`Invalid 'correct' value for row ${index}:`, row.correct);
    }

    return {
      question: row.question,
      options: options,
      correct: correctIndex, // Updated to use the correct index instead of hardcoded values
    };
  });

  console.log("Quiz Array:", quizArray); // Debugging output

  const questionsPerPage = 1; // Only one question per page
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState(
    Array(quizArray.length).fill("")
  );
  const [firstSelectedOptions, setFirstSelectedOptions] = useState(
    Array(quizArray.length).fill(null)
  ); // Track the first-selected option for each question
  const [scores, setScores] = useState(Array(quizArray.length).fill(0)); // NEW: Array to track individual scores per question
  const [explaination, setExplaination] = useState(null);
  const [loader, setLoader] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showRecommendation, setShowRecommendation] = useState(false);

  // Determine if the current page is fully answered
  const isPageAnswered = selectedOptions
    .slice(currentPage * questionsPerPage, (currentPage + 1) * questionsPerPage)
    .every((option) => option !== "");

  // Handle option change
  const handleOptionChange = async (event, questionIndex) => {
    setExplaination(null);
    const selectedOption = parseInt(event.target.value, 10);
    console.log("Selected Option:", selectedOption); // Debugging

    const correctOption = quizArray[questionIndex].correct; // Correct index logic from quiz array
    console.log("Correct Option:", correctOption); // Debugging

    // Update selected options
    setSelectedOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[questionIndex] = selectedOption;
      console.log("Updated Selected Options:", newOptions); // Debugging
      return newOptions;
    });

    // Track the first-selected option
    setFirstSelectedOptions((prevFirstOptions) => {
      const updatedFirstOptions = [...prevFirstOptions];
      if (updatedFirstOptions[questionIndex] === null) {
        updatedFirstOptions[questionIndex] = selectedOption;
        console.log(
          `First option selected for question ${questionIndex + 1}: ${
            quizArray[questionIndex].options[selectedOption]
          }`
        );
      }
      return updatedFirstOptions;
    });

    // Update scores
    setScores((prevScores) => {
      const updatedScores = [...prevScores];
      updatedScores[questionIndex] = selectedOption === correctOption ? 1 : 0; // Score logic
      console.log("Updated Scores:", updatedScores); // Debugging
      return updatedScores;
    });

    // Fetch explanation for incorrect answers
    if (selectedOption !== correctOption) {
      setLoader(true);
      try {
        const response = await fetch(
          "https://question-explaination-api-ymlc7ovnxa-uc.a.run.app/generate_explaination",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              question: quizArray[questionIndex].question,
              options: quizArray[questionIndex].options.map(
                (option, index) => ({
                  [index]: option,
                })
              ),
              selectedOption: quizArray[questionIndex].options[selectedOption],
            }),
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setExplaination(data.explaination);
        console.log("Fetched Explanation:", data.explaination); // Debugging
      } catch (error) {
        console.error("Error fetching explanation:", error);
        setExplaination(
          "An error occurred while generating the explanation. Please try again."
        );
      } finally {
        setLoader(false);
      }
    }
  };

  // Handle next button click
  const handleFinishClick = () => {
    // Calculate the score based on the firstSelectedOptions array
    const calculatedScore = firstSelectedOptions.reduce(
      (score, selectedOption, index) => {
        if (selectedOption === quizArray[index].correct) {
          return score + 1;
        }
        return score;
      },
      0
    );

    console.log("Calculated Score Based on First Selection:", calculatedScore);

    // Update the scores state with the calculated score


    // Show recommendations based on the calculated score
    setShowRecommendation(true);
  };

  const totalScore = scores.reduce((acc, score) => acc + score, 0);
  // Handle next button click
  const handleNextClick = () => {
    setExplaination(""); // Reset explanation

    // Debugging first option tracking
    const firstSelectedOptionIndex = firstSelectedOptions[currentPage];
    if (firstSelectedOptionIndex !== null) {
      console.log(
        `First selected option for question ${currentPage + 1}: ${
          quizArray[currentPage].options[firstSelectedOptionIndex]
        }`
      );
    } else {
      console.log(
        `No option was selected initially for question ${currentPage + 1}`
      );
    }

    // Advance to next question or finish
    const totalPages = Math.ceil(quizArray.length / questionsPerPage);
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    } else {
      setShowResult(true);
    }
  };

  // Ensure scores are based on first option selected
useEffect(() => {
  setScores(
    firstSelectedOptions.map((selectedOption, index) => {
      const correctOption = quizArray[index]?.correct;
      return selectedOption === correctOption ? 1 : 0;
    })
  );
}, [firstSelectedOptions, quizArray]);

  // Custom scrollbar styling
  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.textContent = `
      .custom-scrollbar::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #FF6652;
        border-radius: 10px;
        padding: 10px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #a0c7dc;
      }
    `;
    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  // Render questions
  const renderQuestions = () => {
    const startIndex = currentPage * questionsPerPage;
    const endIndex = Math.min(startIndex + questionsPerPage, quizArray.length);

    return quizArray.slice(startIndex, endIndex).map((assessment, index) => (
      <div
        key={startIndex + index}
        style={{
          backgroundColor: " #fbf1ea",
          padding: "16px",
          margin: "16px 0",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h5" gutterBottom>
          {assessment.question}
        </Typography>
        <RadioGroup
          value={selectedOptions[startIndex + index]}
          onChange={(event) => handleOptionChange(event, startIndex + index)}
          name={`question${startIndex + index}`}
        >
          {assessment.options.map((option, i) => (
            <FormControlLabel
              key={i}
              value={i}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>

        {/* Display Selected Option and Score */}
        {selectedOptions[startIndex + index] !== "" && (
          <div
            style={{
              marginTop: "10px",
              backgroundColor: "#fef4e8",
              padding: "8px",
              borderRadius: "6px",
            }}
          >
            <Typography variant="body1">
              <strong>Selected Option:</strong>{" "}
              {assessment.options[selectedOptions[startIndex + index]]}
            </Typography>
            <Typography variant="body1">
              <strong>Score:</strong> {scores[startIndex + index]}
            </Typography>
          </div>
        )}

        {/* Explanation Section */}
        {explaination ? (
          <div className="border-2 p-2 rounded-lg border-[#FF6652]">
            <p className="custom-scrollbar h-60 overflow-auto ">
              {explaination}
            </p>
          </div>
        ) : loader ? (
          <CircularProgress className="text-center mx-auto" />
        ) : (
          ""
        )}
      </div>
    ));
  };

  return (
    <Container maxWidth="lg">
      {showRecommendation ? (
        <RecommendationComponent
          score={totalScore} // Use the calculated score
          outOf={quizArray.length}
        />
      ) : (
        <>
          {quizArray.length > 0 ? (
            renderQuestions()
          ) : (
            <div style={{ marginTop: 100, marginBottom: 100, marginLeft: 10 }}>
              <h4 className="text-center text-[#2A80D5] text-[20px]  sm:text-[28px] font-semibold">
                Assessment is loading..
              </h4>
            </div>
          )}
          {showResult && !showRecommendation ? (
            <Button
              variant="contained"
              style={{
                marginLeft: "30%",
                marginRight: "30%",
                backgroundColor: "#ff6652",
                color: "#fff",
                textAlign: "center",
                width: "40%",
              }}
              onClick={handleFinishClick}
            >
              Show Recommendation
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled={!isPageAnswered}
              style={{
                marginLeft: "30%",
                marginRight: "30%",
                backgroundColor: "#ff6652",
                color: "#fff",
                textAlign: "center",
                width: "40%",
              }}
              onClick={handleNextClick}
            >
              Next
            </Button>
          )}
        </>
      )}
    </Container>
  );
};

export default AssessmentComponent;
